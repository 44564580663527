
export default {
  render(createElement) {
    return createElement(
      'div',
      {
        id: `c${this.id}`,
        class: [this.$style['ce-frame'], this.$style[this.bgColor], 'ce-frame'],
      },
      [
        createElement(
          'div',
          {
            class: [this.$style.inner],
          },
          this.$slots.default
        ),
      ]
    )
  },
}
